<template>
  <div id="">
    <Loader v-if="loading" />

    <div v-if="!loading">
      <div class="row">
        <div class="col-lg-12">
          <div style="position: relative;" class="conteudo">
            <div v-if="fetchFailed === true && userLevel == 'pecsmart'" class="chart-without-data">
              <h1 class="error-message">{{errorMessage}}</h1>
            </div>
            <div v-else-if="fetchFailed === true && userLevel !== 'pecsmart'" class="chart-without-data">
              <h1 class="error-message">SEM DADOS</h1>
              <h1 class="error-message">DE CONSUMO DISPONÍVEIS</h1>
            </div>

            <div v-else-if="chartWarning(siloUnloadDataset) && chartWarning(siloUnloadDatasetAccumulated)" class="chart-without-data">
              <h1>SEM DADOS</h1>
            </div>

            <div class="chart-active">
              <div class="chart-title">
                <p>Diário</p>
              </div>

              <SliderButton
                v-bind:TrackType="'track-unload'"
                @changeChart="changeUnloadChart()"
              />

              <div class="chart-title">
                <p>Acumulado</p>
              </div>
            </div>

            <SmartFeedChart
              ref="chartComponent"
              :key="componentKeyUnload"
              v-bind:sets="unloadChartDisplay"
              v-bind:yAxesScale="yAxesScaleUnload"
              v-bind:offset="chartOffsetUnload"
              v-bind:first_date="batch.date_accomodation"
              v-bind:last_date="last_date"
              v-bind:text="'CONSUMO CONSOLIDADO'"
              :height="200"
              :width="800"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmartFeedChart from "./SmartFeedChart.vue";
import SliderButton from "./SliderButton.vue";
import Loader from "./Loader.vue";

export default {
  name: "SmartFeed",
  props: ["sensor", "workingSensors"],
  components: {
    SmartFeedChart,
    SliderButton,
    Loader,
  },
  data() {
    return {
      loading: true,
      fetchFailed: false,
      unloadChartData: this.unloadData,
      chartOffsetUnload: true,
      yAxesScaleUnload: true,
      componentKeyUnload: 0,
    };
  },
  methods: {
    chartWarning(dataset) {
      if (dataset.length < 1) return true;
      return false;
    },
    changeUnloadChart() {
      this.chartOffsetUnload = !this.chartOffsetUnload;
      this.yAxesScaleUnload = !this.yAxesScaleUnload;
      if (
        this.unloadChartData === this.unloadData ||
        this.unloadChartData == null
      ) {
        this.unloadChartData = this.unloadAccData;
      } else {
        this.unloadChartData = this.unloadData;
      }
      this.forceRerenderUnload();
    },
    forceRerenderUnload: function() {
      this.componentKeyUnload += 1;
    },
  },
  computed: {
    unloadChartDisplay() {
      if (this.unloadChartData == null) return this.unloadData;
      return this.unloadChartData;
    },
    computed_level() {
      if (this.sensorsData?.last_hour_level == null) return "Sem Nível";
      return `${this.sensorsData.last_hour_level}%`;
    },
    computed_weight() {
      if (this.sensorsData?.last_hour_weight == null) return "Sem Peso";
      return `${this.sensorsData.last_hour_weight} kg`;
    },
    sensorsData() {
      try {
        return this.$store.getters.getSmartFeedConsolidatedData.find(
          (item) => item.barn === this.barn.pk
        );
      } catch (err) {
        return null;
      }
    },
    siloUnloadDataset() {
      try {
        return this.sensorsData.data.map((item) => {
          return {
            x: item.date,
            y: Number(item.consume).toFixed(2),
          };
        });
      } catch (err) {
        return [];
      }
    },
    siloUnloadDatasetAccumulated() {
      try {
        let accumulated = 0;
        return this.sensorsData.data.map((item) => {
          let y = item.consume + accumulated;
          accumulated += item.consume;
          return {
            x: item.date,
            y: Number(y).toFixed(2),
          };
        });
      } catch (err) {
        return [];
      }
    },
    unloadData() {
      const setting = [
        {
          label: "Consumo Diário",
          pointRadius: 3,
          data: this.siloUnloadDataset,
          type: "bar",
          fill: false,
          borderColor: "rgba(137, 194, 91, 1)",
          backgroundColor: "rgba(137, 194, 91, 1)",
          borderWidth: 2,
          yAxisID: "Consume",
          barPercentage: 0.75,
        },
      ];
      return setting;
    },
    unloadAccData() {
      const setting = [
        {
          label: "Consumo Acumulado",
          pointRadius: 3,
          data: this.siloUnloadDatasetAccumulated,
          type: "line",
          fill: true,
          borderColor: "rgba(91, 138, 52, 0.9)",
          backgroundColor: "rgba(91, 138, 52, 0.9)",
          borderWidth: 2,
          yAxisID: "AccumulatedConsume",
        },
      ];
      return setting;
    },
    barn() {
      return this.$store.getters.getBarnTarget;
    },
    batch() {
      if (this.$store.getters.getBatchTarget == null) {
        return this.barn.batch
      }
      return this.$store.getters.getBatchTarget
    },
    last_date() {
      if (this.batch.date_departure != null) return this.batch.date_departure;
      let today = new Date();
      today.setDate(today.getDate());
      return today;
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
    errorMessage() {
      return this.$store.getters.getErrorMessage
    }
  },
  async created() {
    let today = new Date();
    today.setDate(today.getDate());
    const today_date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    const barn = this.barn.pk;
    const accommodation = this.batch.date_accomodation
    const departure = this.batch.date_departure != null ? this.batch.date_departure : today_date;

    try {
      await this.$store.dispatch("fetchFeedConsolidatedData", {barn, accommodation, departure,});
    }
    catch(err) {
      this.fetchFailed = true;
    }

    this.loading = false;
  },
};
</script>

<style scoped>
.chart-without-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.chart-active {
  margin-left: 3%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  left: 0;
  height: 24px;
}

.chart-active .chart-title {
  display: flex;
  align-items: center;
}

.chart-active .chart-title p {
  margin: 0;
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.warning .text {
  background: #78b843;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  font-size: 30px;
}

.warning .text h1 {
  display: flex;
  justify-content: center;
}

.warning .text p {
  display: flex;
  justify-content: center;
  margin: 0;
}

.error-message {
  font-size: 2rem;
  color: #952323;
}

.mobile-info {
  display: none;
}

.situacao-atual-feed-bom {
  background: #78b843 !important;
}

.situacao-atual-feed-medio {
  background: #e2bf65 !important;
}

.situacao-atual-feed-ruim {
  background: #a94949 !important;
}

strong {
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .silo {
    display: none;
  }

  .mobile-info {
    display: grid;
  }
}

@media screen and (max-width: 768px) {
  .warning {
    height: 250px;
  }

  .warning h1 {
    font-size: 30px;
  }

  .warning p {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .warning h1 {
    font-size: 25px;
  }

  .warning p {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .warning {
    height: 200px;
  }

  .warning h1 {
    font-size: 20px;
  }

  .warning p {
    font-size: 10px;
  }
}
</style>
